import React from "react";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { purple, teal } from "@material-ui/core/colors";

export const noShortcutTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: teal[800],
    },
    secondary: {
      main: purple[400],
    },
  },
});

export function NoShorcutRoot({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider theme={noShortcutTheme}>
      <main>{children}</main>
    </ThemeProvider>
  );
}
