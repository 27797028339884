import React from "react";

import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import { NoShorcutRoot } from "../../../../interactive-tutorial/lib/Theme";

export default function TopLayout({ children, theme }) {
  return (
    <ThemeTopLayout theme={theme}>
      <NoShorcutRoot>{children}</NoShorcutRoot>
    </ThemeTopLayout>
  );
}
